export const environment = {
  name: 'sit',
  production: true,
  hostApi: 'http://159.138.242.188:3000', //-- api front-web
  hostName: 'https://ffd-cms-dev.automer.io',
};

// '2003704839': {
//   liffId: '2003704839-KD40yeYj',
//   icon: './assets/line/pvd.jpeg',
//   name: 'เข้าสู่ระบบด้วย Line PVD',
//   clientId: '2003704839',
//   client_secret: '5e1b3e6e693a8d687c5a86aca41ada00',
// }